<div class="m-container m-container-wrap m--border m--border-radius container">
  <div
    class="m-container__head"
    *ngIf="title.length || subTitle.length || routeCreate.length"
  >
    <h5 *ngIf="title.length">{{ title }}</h5>
    <p *ngIf="subTitle.length">{{ subTitle }}</p>
    <a
      *ngIf="routeCreate.length"
      [routerLink]="[routeCreate]"
      class="m-button m-button-create"
      >+
      {{
        (routeLabel ? routeLabel : "achievement.create-achievement") | translate
      }}</a
    >
  </div>
  <div class="m-container__body">
    <ng-content></ng-content>
  </div>
</div>
