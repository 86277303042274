import { DepartmentHeadModule } from './modules/department-head/department-head.module';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminGuard } from './core/guards/admin.guard';
import { SubAdminGuard } from './core/guards/subadmin.guard';
import { SubadminLayoutComponent } from './layouts/subadmin-layout/subadmin-layout.component';
import { TermOfUseComponent } from './modules/auth/pages/term-of-use/term-of-use.component';
import { PolicyComponent } from './modules/auth/pages/policy/policy.component';
import { TermOfUseComponent as TermOfUseComponentV2 } from './modules/auth/pages/term-of-use-v2/term-of-use.component';
import { PolicyComponent as PolicyComponentV2 } from './modules/auth/pages/policy-v2/policy.component';
import { DepartmentHeadLayoutComponent } from './layouts/department-head-layout/department-head-layout.component';
import { DepartmentHeadGuard } from './core/guards/department-head.guard';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { GuestLayoutComponent as GuestLayoutComponentV2 } from './layouts/guest-layout-v2/guest-layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing/landing.module').then((m) => m.LandingModule),
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'dashboard',
        component: DashboardLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'admin',
        component: AdminLayoutComponent,
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'subadmin',
        component: SubadminLayoutComponent,
        canActivate: [SubAdminGuard],
        loadChildren: () =>
          import('./modules/subadmin/subadmin.module').then(
            (m) => m.SubadminModule
          ),
      },
      {
        path: 'department-head',
        component: DepartmentHeadLayoutComponent,
        canActivate: [DepartmentHeadGuard],
        loadChildren: () =>
          import('./modules/department-head/department-head.module').then(
            (m) => m.DepartmentHeadModule
          ),
      },
      {
        path: 'guest/project/:projectId/practice',
        component: GuestLayoutComponent,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('./modules/guest-practice/new-practice.module').then(m=>m.NewPracticeModule),
      },
      {
        path: 'guest/v2/practice',
        component: GuestLayoutComponentV2,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('./modules/guest-video-list/guest-video-list.module').then(m=>m.GuestVideoListModule),
      },
      {
        path: 'guest/v2/project/:projectId/practice',
        component: GuestLayoutComponentV2,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('./modules/guest-practice-v2/new-practice.module').then(m=>m.NewPracticeModule),
      },
      {
        path: 'auth',
        component: AuthLayoutComponent,
        canActivate: [NoAuthGuard],
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'v2',
        component: AuthLayoutComponent,
        data: {newTheme: true},
        children: [
          {
            path: 'term-of-use',
            component: TermOfUseComponentV2
          },
          {
            path: 'policy',
            component: PolicyComponentV2
          },
        ],
      },
      {
        path: '',
        component: AuthLayoutComponent,
        children: [
          {
            path: 'term-of-use',
            component: TermOfUseComponent
          },
          {
            path: 'policy',
            component: PolicyComponent
          },
        ],
      },
      {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
