<div class="m-dialog-container">
  <h1 mat-dialog-title>Confirm</h1>
  <div mat-dialog-content>
    <p>{{ messenger }}</p>
  </div>
  <div mat-dialog-actions>
    <button class="btn btn-primary" (click)="handleClickConfirmed()">
      Confirmed
    </button>
    <button
      class="btn btn-secondary"
      (click)="handleClickClose()"
      cdkFocusInitial
    >
      Close
    </button>
  </div>
</div>
