import { ACCESS_TOKEN_NAME, UserRole } from './../../../utils/constants';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AuthModel } from 'src/app/shared/models/auth.model';
import { UserModel } from 'src/app/shared/models/user.model';
import { ApiService } from '../http/api.service';
import {
  ForgotPwdForm,
  ResetPwdForm,
  SendConfirmationForm,
  ActivateAccountForm,
} from 'src/app/shared/models/forms';

export const AUTH_REDIRECT_URL = 'auth_redirect_url';
export const AUTH_MODEL = 'auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private subscription: Subscription = new Subscription(); // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private isLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private currentUser: UserModel | undefined;

  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(
    private api: ApiService,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) {}

  public static getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_NAME) ?? '';
  }

  public isAuthenticated(): boolean {
    const token = this.jwtHelper.tokenGetter();
    return this.hasFaceVerified() && !this.jwtHelper.isTokenExpired(token);
  }

  public isAdmin(): boolean {
    const token = this.jwtHelper.tokenGetter();
    return this.jwtHelper.decodeToken(token)?.role === UserRole.ADMIN;
  }

  public isSubAdmin(): boolean {
    const token = this.jwtHelper.tokenGetter();
    const decoded = this.jwtHelper.decodeToken(token);
    return decoded?.role === UserRole.SUB_ADMIN;
  }

  public isDepartmentHead(): boolean {
    const token = this.jwtHelper.tokenGetter();
    const decoded = this.jwtHelper.decodeToken(token);
    return decoded?.role === UserRole.DEPARTMENT_HEAD;
  }

  public isAdminWithToken(token: string): boolean {
    const payload = this.jwtHelper.decodeToken(token);
    return payload?.role === UserRole.ADMIN;
  }

  public isSubAdminWithToken(token: string): boolean {
    const payload = this.jwtHelper.decodeToken(token);
    return (
      payload?.role === UserRole.SUB_ADMIN ||
      payload?.role === UserRole.DEPARTMENT_HEAD
    );
  }

  public hasFaceVerified(): boolean {
    const token = this.jwtHelper.tokenGetter();
    return this.jwtHelper.decodeToken(token)?.faceVerified ?? false;
  }
  public canFaceVerify(): boolean {
    return !this.hasFaceVerified() && this.jwtHelper.tokenGetter() !== '';
  }

  public getUserId(): string {
    const token = this.jwtHelper.tokenGetter();
    const decoded = this.jwtHelper.decodeToken(token);
    return decoded.id;
  }

  public getDecodedToken(): any {
    const token = this.jwtHelper.tokenGetter();
    const decoded = this.jwtHelper.decodeToken(token);
    return decoded;
  }

  public getCurrentUser(): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    if (this.currentUser === undefined) {
      return this.api.get('user/profile').pipe(
        map((response: any) => {
          this.currentUser = new UserModel().setUser(response.data);
          return this.currentUser;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.currentUser);
        observer.complete();
      });
    }
  }

  /**
   * login
   */
  public login(credential: {
    email: string;
    password: string;
  }): Observable<Object> {
    this.isLoadingSubject.next(true);
    return this.api.post('auth/login', credential).pipe(
      map((response: any) => {
        localStorage.setItem(ACCESS_TOKEN_NAME, response.token);
        // localStorage.setItem('password', credential.password);
        return response;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  /**
   * send confirm email
   */
  public sendConfirmationMail(
    params: SendConfirmationForm
  ): Observable<Object> {
    this.isLoadingSubject.next(true);
    return this.api.post('mailer/send-confirmation', params).pipe(
      map((response: any) => {
        return response;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  /**
   * activate account
   */
  public activateAccount(params: ActivateAccountForm): Observable<Object> {
    this.isLoadingSubject.next(true);
    return this.api.post('auth/activate', params).pipe(
      map((response: any) => {
        return response;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  /**
   * Forgot Password
   */
  forgotPassword(params: ForgotPwdForm): Observable<Object> {
    this.isLoadingSubject.next(true);
    return this.api.post('mailer/forgotpassword', params).pipe(
      map((response: any) => {
        return response;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  /**
   * Reset Password
   */
  resetPassword(params: ResetPwdForm): Observable<Object> {
    this.isLoadingSubject.next(true);
    return this.api.post('mailer/resetpassword', params).pipe(
      map((response: any) => {
        return response;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  /**
   * logout
   */
  public logout() {
    // unstaged version
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(AUTH_REDIRECT_URL);
    localStorage.removeItem(AUTH_MODEL);
    this.isLoadingSubject.next(false);
    this.router.navigate(['/auth/login']);
    this.currentUser = undefined;

    // API call version
    // this.isLoadingSubject.next(true);
    // return this.api.post('auth/logout').pipe(
    //   map((response: any) => {
    //     localStorage.removeItem(ACCESS_TOKEN_NAME);
    //     return response;
    //   }),
    //   catchError(err => {
    //     localStorage.removeItem(ACCESS_TOKEN_NAME);
    //     return throwError(err);
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  switchRole(role: string) {
    return this.api.put('user/switch-role', {
      role
    });
  }

  /**
   * Do face verification
   * @param imgBase64 the base64 form of snapshot
   */
  doFaceVerification(imgBase64: string): Observable<string | undefined> {
    if (!this.canFaceVerify()) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.api
      .post('auth/login/faceVerification', {
        imgBase64: imgBase64,
      })
      .pipe(
        map((response: any) => {
          localStorage.setItem(ACCESS_TOKEN_NAME, response.token);
          return response;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  updateCurrenctUser(data: {
    academicQualification: string;
    birthday: string;
    countryRegion: string;
    firstName: string;
    gender: string;
    industry: string;
    jobTitle: string;
    lastName: string;
    occupation: string;
    password: string;
    workingExperience: number;
    description: string;
  }): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.api.patch('user/profile', data).pipe(
      map(() => {
        return true;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  updateCurrenctUserAvatar(data: {
    avatarBase64: string;
  }): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.api.patch('user/avatar', data).pipe(
      map(() => {
        return true;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  /**
   * Registration account
   * @param data
   */
  registration(data: {
    referenceCode: string;
    email: string;
    password: string;
    lastName: string;
    firstName: string;
    birthday: string;
    gender: string;
    workingExperience: number;
    countryRegion: string;
    jobTitle: string;
    occupation: string;
    industry: string;
    academicQualification: string;
    imgBase64: string;
  }): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.api.post('auth/signup', data).pipe(
      map(() => {
        return true;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  checkAccessCode(referenceCode: string): Observable<object> {
    this.isLoadingSubject.next(true);
    return this.api
      .get(`companies/verify-code?referenceCode=${referenceCode}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    if (this.isAuthenticated()) {
      localStorage.setItem(AUTH_MODEL, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(localStorage.getItem(AUTH_MODEL));
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

/*
export class AuthService {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private isLoadingSubject: BehaviorSubject<boolean>;
  private authLocalStorageToken = `moodie2auth`;

  loginRedirectToSubject = new BehaviorSubject("/");
  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  errorSubject: BehaviorSubject<any>

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(null);
    this.errorSubject = new BehaviorSubject<any>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.introspectToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      localStorage.removeItem(this.authLocalStorageToken);
      this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
      this.router.navigate([loginRoute], {
        queryParams: {},
      });
      return;
    }
    const accessToken = auth.accessToken
    localStorage.removeItem(this.authLocalStorageToken);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.authHttpService.logout(auth.accessToken)
    this.router.navigate([loginRoute], {
      queryParams: {},
    });
  }

  checkEmailExist(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.checkEmailExist(email).pipe(
      map((res) => {
        const user = this.currentUserValue;
        if (res.emailExist) {
          return true
        }
        return false;
      }),
      catchError((err) => {
        console.log(err);
        return of(true)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getFaceVerification(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getFaceVerification(auth.accessToken).pipe(
      map((res) => {
        const user = this.currentUserValue;
        if (res.faceVerified) {
          user.faceVerified = true;
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  doFaceVerification(imgBase64: string): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.doFaceVerification(imgBase64, auth.accessToken).pipe(
      map((res) => {
        const user = this.currentUserValue;
        if (res.faceVerified) {
          user.faceVerified = true;
        } else {
          user.faceLoginAttempt = res.attempt;
        }
        this.currentUserSubject.next(user);

        return user;
      }),
      catchError((err) => {
        this.errorSubject.next(err)
        console.error('err', err);
        const user = this.currentUserValue;
        return of(this.currentUserValue);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  introspectToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.introspect(auth.accessToken).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.accessToken).pipe(
      map((user: UserModel) => {
        if (user) {
          const tmpuser = this.currentUserValue;
          tmpuser.setUser(user)
          this.currentUserSubject = new BehaviorSubject<UserModel>(tmpuser);
        } else {
          this.logout();
        }
        return user;
      }),
      switchMap(() => this.getFaceVerification()),
      switchMap(() => of(this.currentUserValue)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  updateCurrentUser(body): Observable<boolean> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(false);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.postCurrentUser(body, auth.accessToken).pipe(
      map(() => true),
      switchMap(() => this.getUserByToken()),
      map(() => true),
      catchError((err) => {
        this.errorSubject.next(err)
        console.error('err', err);
        return of(false);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(data: {
    email: string;
    password: string;
    lastName: string;
    firstName: string;
    birthday: string;
    gender: string;
    workingExperience: string;
    countryRegion: string;
    jobTitle: string;
    occupation: string;
    industry: string;
    academicQualification: string;
    imgBase64: string;
  }): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.signup(data).pipe(
      map(() => {
        // this.isLoadingSubject.next(false);
        return true
      }),
      // switchMap(() => this.login(data.email, data.password)),
      catchError((err) => {
        this.errorSubject.next(err)
        console.error('err', err);
        return of(false);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(
        map((v) => true),
        catchError((err) => {
          this.errorSubject.next(err)
          console.error('err', err);
          return of(false);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  resetPassword(body: {
    token: string;
    password: string;
  }): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(body)
      .pipe(
        map((v) => true),
        catchError((err) => {
          this.errorSubject.next(err)
          console.error('err', err);
          return of(false);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      const usermodel = new UserModel()
      usermodel.setAuth(auth)
      this.currentUserSubject.next(usermodel);
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
*/
