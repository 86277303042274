import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent {
  checked: boolean;
  currentLang: string;
  constructor(
    private translate: TranslateService,
    private activeatedRoute: ActivatedRoute
  ) {
    this.currentLang =
      this.activeatedRoute.snapshot.queryParamMap.get('lang') ||
      this.translate.currentLang;
  }
}
