import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface GuestProjectSetting {
    language?: string;
    background?: string;
    backgroundMobile?: string;
    backgroundTextColor?: string;
}

@Injectable()
export class CommonDataService {
  private guestProjectSetting = new BehaviorSubject<GuestProjectSetting>(null);
  currentGuestProjectSetting = this.guestProjectSetting.asObservable();

  constructor() { }

  changeGuestProjectSetting(guestProjectSetting: GuestProjectSetting) {
    this.guestProjectSetting.next(guestProjectSetting)
  }
}