import { ApiService } from 'src/app/core/api/api.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import axios from 'axios';

@Component({
  selector: 'import-multiple-user',
  templateUrl: './import-multiple-user.component.html',
  styleUrls: ['./import-multiple-user.component.scss'],
})
export class ImportMultipleUserComponent implements OnInit {
  file: any = null;
  clicked: boolean = false;
  uploadInvalid: string = null;
  uploadError: any = null;
  uploadProgressSubject = new BehaviorSubject(0);
  uploadProgress = this.uploadProgressSubject.asObservable();
  importPercentSubject = new BehaviorSubject(0);
  importPercent = this.importPercentSubject.asObservable();
  showSpinner: boolean = false;
  temp = 10;
  interval: any = null;

  @Input() companyId: string;
  constructor(
    private translate: TranslateService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.getPercentImport();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  async uploadFiles(event: any) {
    this.uploadError = null;
    this.file = event.target.files[0];
    this.clicked = true;
    if (
      this.file['type'].includes('csv') ||
      this.file['type'].includes('vnd.ms-excel') ||
      this.file['type'].includes(
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.uploadInvalid = null;
      const formData: FormData = new FormData();
      formData.append('excelFile', this.file, this.file.name);

      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
        onUploadProgress: (progressEvent: any) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.uploadProgressSubject.next(percentCompleted);
          if (percentCompleted === 100) this.showSpinner = true;
        },
      };

      axios
        .post(`${environment.apiUrl}/user/bulk-users`, formData, config)
        .then(() => {
          this.getPercentImport();
        })
        .finally(() => {
          this.clicked = false;
          this.showSpinner = false;
          this.uploadProgressSubject.next(0);
        })
        .catch(({ response }) => {
          this.uploadError = response.data;
          this.file = null;
        });
    } else {
      this.uploadInvalid = this.translate.instant('editor.correct-excel');
      return;
    }
  }

  getPercentImport() {
    this.interval = setInterval(async () => {
      await this.apiService
        .get({ url: `user/query-insert-records?companyId=${this.companyId}` })
        .then((res) => {
          if (res.code !== 0) {
            this.importPercentSubject.next(res.progress);
            if (res.progress === 100) clearInterval(this.interval);
          } else throw res;
        })
        .catch(() => {
          clearInterval(this.interval);
          this.importPercent = null;
        });
    }, 500);
  }
}
