<ng-container *ngIf="newTheme; else oldTheme">
  <div class="new-theme">
    <div class="content-container">
      <div class="project-name-banner">
        <img class="moodie-logo" width="50%" src="/assets/media/moodie_logo_orange.png" alt="">
      </div>
      <div class="content-body flex-item panel" >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #oldTheme>
  <div class="auth flex-wrapper flex-even">
    <div class="flex-item">
      <div class="logo-section flex-wrapper flex-center">
        <div class="flex-item">
          <a class="logo" [routerLink]="['/auth/login']"
            ><img src="/assets/media/auth-logo.png" alt="Moodie Logo"
          /></a>
        </div>
      </div>
      <div
        class="flex-item"
        style="position: absolute; bottom: 12px; width: 100%"
      >
        <div class="action flex-wrapper">
          <div class="flex-item">
            <a class="setting" (click)="openSetting()"
              ><img src="/assets/media/icon-sb-cog.png" alt="Settings"
            /></a>
          </div>
        </div>
      </div>
      <nz-modal
        [(nzVisible)]="isSettingOpen"
        [nzFooter]="null"
        nzCentered
        (nzOnCancel)="isSettingOpen = false"
      >
        <ng-container *nzModalContent>
          <div fxFLex fxLayout="column">
            <div
              class="row"
              fxFlex
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <div class="title text-center" fxFlex="30%">
                <p>{{ "common.language" | translate }}</p>
              </div>
              <div
                class="content"
                fxFlex="70%"
                fxLayout="row"
                style="justify-content: center"
              >
                <p
                  (click)="setLang('hk')"
                  style="margin-right: 12px; cursor: pointer"
                >
                  繁
                </p>
                <p
                  (click)="setLang('en')"
                  style="margin-right: 12px; cursor: pointer"
                >
                  ENG
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </nz-modal>
    </div>
  
    <div class="flex-item panel">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>

