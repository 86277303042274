import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog.component';

@NgModule({
  declarations: [ProgressSpinnerDialogComponent],
  imports: [MatProgressSpinnerModule],
  exports: [ProgressSpinnerDialogComponent],
})
export class ProgressSpinnerDialogModule {}
