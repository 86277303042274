<button nz-button [nzType]="'primary'" (click)="showModal()">
  <span>{{"clients.create-user" | translate}}</span>
</button>
<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Create User"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <nz-tabset
      [(nzSelectedIndex)]="selected"
      (nzSelectChange)="onTabChange($event)"
    >
      <nz-tab nzTitle="Create Company">
        <form
          nz-form
          [formGroup]="companyForm"
          class="create-user-form"
          (ngSubmit)="onCompanySubmit()"
        >
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input name">
              <nz-form-label nzFor="name">{{"clients.name" | translate}}</nz-form-label>
              <nz-input-group>
                <input nzInput formControlName="name" placeholder="Name" />
              </nz-input-group>
            </nz-form-control>
            <nz-form-control nzErrorTip="Please enter email">
              <nz-form-label nzFor="email">{{"clients.email" | translate}}</nz-form-label>
              <nz-input-group>
                <input nzInput formControlName="email" placeholder="Email" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="primary" class="create-user-form-button">
            {{"clients.confirm" | translate}}
          </button>
        </form>
      </nz-tab>
      <nz-tab nzTitle="Create Department">
        <form
          nz-form
          [formGroup]="departmentForm"
          (ngSubmit)="onDepartmentSubmit()"
        >
          <nz-form-item>
            <nz-form-label [nzSpan]="5" nzFor="companyId" nzRequired
              >{{"clients.company" | translate}}</nz-form-label
            >
            <nz-form-control
              [nzSpan]="12"
              nzErrorTip="Please select your company!"
            >
              <nz-select
                id="companyId"
                formControlName="companyId"
                nzPlaceHolder="Select a option and change input text above"
              >
                <nz-option
                  *ngFor="let company of companies; index as i"
                  [nzValue]="company.id"
                  [nzLabel]="company.name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input name">
              <nz-form-label nzFor="name">{{"clients.name" | translate}}</nz-form-label>
              <nz-input-group>
                <input nzInput formControlName="name" placeholder="Name" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please Enter Email">
              <nz-form-label nzFor="email">{{"clients.email" | translate}}</nz-form-label>
              <nz-input-group>
                <input nzInput formControlName="email" placeholder="Email" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="primary" class="create-user-form-button">
            {{"clients.confirm" | translate}}
          </button>
        </form>
      </nz-tab>
    </nz-tabset>
  </ng-container>
  <ng-template *nzModalFooter></ng-template>
</nz-modal>
