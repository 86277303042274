import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
// import { Color, Label, MultiDataSet } from 'ng2-charts';
// export * from '../util/Gauge';
// import '../../../../utils/chartjst-extention.d.ts';
import '../../../../utils/gauge';

@Component({
  selector: 'app-widget-gauge-score',
  templateUrl: './widget-gauge-score.component.html',
  styleUrls: ['./widget-gauge-score.component.scss'],
})
export class WidgetGaugeScoreComponent implements OnInit {
  private _score: number | string;

  @Input('color') color: string = '#FF6666';
  @Input('score') set score(value: number | string) {
    this._score = value || 0;
    this.configChart();
  };

  get score(): number | string {
    return this._score;
  }

  public chartOption: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    events: [],
    cutoutPercentage: 80,
    indicatorColor: 'black',
  } as ChartOptions;
  public chartColors: any[] = [];
  public chartDatasets: any[] = [];
  public chartType: ChartType = 'tsgauge' as ChartType;

  constructor() {}

  ngOnInit(): void {
    this.configChart();
  }

  configChart() {
    let reprScore: number = 0;
    // if (this.score >= 2) {
    //   reprScore = 20;
    // }
    // if (this.score >= 4) {
    //   reprScore = 40;
    // }
    // if (this.score >= 6) {
    //   reprScore = 60;
    // }
    // if (this.score >= 8) {
    //   reprScore = 80;
    // }
    // if (this.score >= 9) {
    //   reprScore = 100;
    // }
    reprScore = Math.round(Number(this.score)) * 10;
    (this.chartOption as any).indicatorColor = this.color;
    this.chartDatasets = [
      {
        borderWidth: 0,
        gaugeData: {
          value: reprScore,
          valueColor: 'transparent',
        },
        gaugeLimits: [0, reprScore, 100],
      },
    ];
    this.chartColors = [
      {
        backgroundColor: [this.color, 'rgba(255, 255, 255, 0.2)'],
        borderColor: [this.color, 'rgba(255, 255, 255, 0.2)'],
        hoverBackgroundColor: [this.color, 'rgba(255, 255, 255, 0.2)'],
        hoverBorderColor: [this.color, 'rgba(255, 255, 255, 0.2)'],
        borderWidth: 0,
      },
    ];
  }
}
