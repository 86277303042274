import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-guest-layout',
  templateUrl: './guest-layout.component.html',
  styleUrls: ['./guest-layout.component.scss']
})
export class GuestLayoutComponent implements OnInit {

  themeData = 'dark';
  lang = 'hk';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.initTheme();
    this.initLang();
  }

  initTheme() {
    document.body.classList.add('dark');
  }

  initLang() {
    this.translate.use(this.lang);
    // window.location.reload();
  }

}
