import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-donut-score',
  templateUrl: './widget-donut-score.component.html',
  styleUrls: ['./widget-donut-score.component.scss'],
})
export class WidgetDonutScoreComponent implements OnInit, AfterViewInit {
  @Input('score')
  set score(score: number | string) {
    if (score == undefined) {
      return;
    }
    let x = Math.round(parseInt(score + ''));
    this.scoreshown = Number(score).toFixed(0);
    this.doughnutChartData = [[x, 100 - x]];
  }
  scoreshown: string = '0';
  // Doughnut
  public doughnutChartOption: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
    cutoutPercentage: 60,
  };
  public doughnutChartColors: Color[] = [
    {
      backgroundColor: ['#00FFD2', 'rgba(255, 255, 255, 0.2)'],
      borderColor: ['#fff', '#fff'],
      borderWidth: 0,
    },
  ];
  public doughnutChartLabels: Label[] = [
    this.translate.instant('project.score'),
    '-',
  ];
  public doughnutChartData: MultiDataSet = [];
  public doughnutChartType: ChartType = 'doughnut';
  private translateSubcription: Subscription = new Subscription();

  constructor(private translate: TranslateService) {
    this.translateSubcription = this.translate.onLangChange.subscribe(() => {
      this.doughnutChartLabels = [this.translate.instant('project.score'), '-'];
    });
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.translateSubcription.unsubscribe();
  }
}
