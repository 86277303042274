import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app.route';
import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NavComponent } from './layouts/nav/nav.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminNavComponent } from './layouts/admin-nav/admin-nav.component';
import { SubadminLayoutComponent } from './layouts/subadmin-layout/subadmin-layout.component';
import { SubadminNavComponent } from './layouts/subadmin-nav/subadmin-nav.component';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  MinusCircleOutline,
  PlusCircleOutline,
} from '@ant-design/icons-angular/icons';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { DepartmentHeadLayoutComponent } from './layouts/department-head-layout/department-head-layout.component';
import { DepartmentHeadNavComponent } from './layouts/department-head-nav/department-head-nav.component';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { GuestLayoutComponent as GuestLayoutComponentV2 } from './layouts/guest-layout-v2/guest-layout.component';
import { CommonDataService } from './shared/services/common-data.service';

const customConfig: ShareButtonsConfig = {
  include: ['facebook', 'twitter'],
  theme: 'modern-light',
  gaTracking: true,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const icons: IconDefinition[] = [MinusCircleOutline, PlusCircleOutline];

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    GuestLayoutComponent,
    GuestLayoutComponentV2,
    AuthLayoutComponent,
    NavComponent,
    AdminLayoutComponent,
    AdminNavComponent,
    SubadminLayoutComponent,
    SubadminNavComponent,
    DepartmentHeadLayoutComponent,
    DepartmentHeadNavComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NzIconModule.forRoot(icons),
    ShareButtonsModule.withConfig(customConfig),
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  providers: [CommonDataService],
  bootstrap: [AppComponent],
})
export class AppModule {}
