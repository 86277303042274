import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/api/api.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  selected = 0;
  companyForm!: FormGroup;
  companies: any[] = [];
  departments: any[] = [];

  departmentForm!: FormGroup;

  userForm!: FormGroup;

  isVisible = false;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.get({ url: '/companies/allCompanies' }).then(({ data }) => {
      this.companies = data;
    });
    this.companyForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
    });
    this.departmentForm = this.fb.group({
      name: [null, [Validators.required]],
      companyId: [null, Validators.required],
      email: [null, Validators.required],
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  onTabChange(e: any) {}

  companyChange(value: string) {
    this.userForm.get('departmentId').setValue(null);
    this.apiService
      .get({
        url: 'companies/departments',
        params: {
          companyId: value,
        },
      })
      .then(({ data }) => {
        this.departments = data;
      });
  }

  onCompanySubmit() {
    for (const i in this.companyForm.controls) {
      this.companyForm.controls[i].markAsDirty();
      this.companyForm.controls[i].updateValueAndValidity();
    }
    if (this.companyForm.valid) {
      this.apiService
        .post({
          url: 'companies',
          body: this.companyForm.value,
        })
        .then(() => {
          this.companyForm.reset();
          this.isVisible = false;
        });
    }
  }

  onDepartmentSubmit() {
    for (const i in this.departmentForm.controls) {
      this.departmentForm.controls[i].markAsDirty();
      this.departmentForm.controls[i].updateValueAndValidity();
    }

    if (this.departmentForm.valid) {
      this.apiService
        .post({
          url: 'companies/departments',
          body: this.departmentForm.value,
        })
        .then(() => {
          this.departmentForm.reset();
          this.isVisible = false;
        });
    }
  }
}
