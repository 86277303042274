import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RadialChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-radar-performance',
  templateUrl: './widget-radar-performance.component.html',
  styleUrls: ['./widget-radar-performance.component.scss'],
})
export class WidgetRadarPerformanceComponent implements OnInit {
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scale: {
      pointLabels: {
        fontFamily: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif',
        fontSize: 16,
        fontColor: 'white',
      },
      ticks: {
        display: false,
        suggestedMax: 100,
        suggestedMin: 0,
        stepSize: 25,
      },
      gridLines: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
  };

  @Input('data')
  set data(data: number[]) {
    this.radarChartData[0].data = data;
  }
  public radarChartLabels: Label[];

  public radarChartData: ChartDataSets[] = [
    {
      data: [65, 59, 90, 81],
      label: this.translate.instant('project.score'),
      backgroundColor: 'rgba(12, 240, 255, 0.7)',
      borderWidth: 3,
      borderColor: '#0CF0FF',
    },
  ];
  public radarChartType: ChartType = 'radar';
  private translateSubcription: Subscription = new Subscription();

  constructor(private translate: TranslateService) {
    this.translateSubcription = this.translate.onLangChange.subscribe(() => {
      this.setChartLabels();
      this.radarChartData = [
        {
          data: [65, 59, 90, 81],
          label: this.translate.instant('project.score'),
          backgroundColor: 'rgba(12, 240, 255, 0.7)',
          borderWidth: 3,
          borderColor: '#0CF0FF',
        },
      ];
    });
  }

  ngOnInit(): void {
    this.setChartLabels();
  }

  ngOnDestroy(): void {
    this.translateSubcription.unsubscribe();
  }

  setChartLabels() {
    const currentLocale = this.translate.currentLang;
    switch (currentLocale) {
      case 'hk':
        this.radarChartLabels = [
          '面部表情',
          '身體動作',
          '說話內容',
          '聲線語氣',
        ];
        break;
      case 'en':
        this.radarChartLabels = ['Face', 'Body', 'Content', 'Voice'];
        break;
      default:
        this.radarChartLabels = [
          '面部表情',
          '身體動作',
          '說話內容',
          '聲線語氣',
        ];
        break;
    }
  }
}
