import { Router } from '@angular/router';
import { UserRoleDropDown, UserRole, ACCESS_TOKEN_NAME } from './../../../utils/constants';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-subadmin-nav',
  templateUrl: './subadmin-nav.component.html',
  styleUrls: ['./subadmin-nav.component.scss'],
})
export class SubadminNavComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private router: Router,
  ) {}
  isSettingOpen = false;
  themeData = 'light';
  isChecked = false;
  roles: any = [];
  selectedRole: any

  ngOnInit(): void {
    this.initTheme();
    this.getRoles();
  }

  getRoles() {
    this.authService.getCurrentUser().subscribe((user) => {
      this.selectedRole = user.role;
      switch (user.baseRole) {
        case UserRole.ADMIN:
          this.roles = UserRoleDropDown;
          break;
        case UserRole.SUB_ADMIN:
          this.roles = UserRoleDropDown.filter(role => role.key !== UserRole.ADMIN && role.key !== UserRole.DEPARTMENT_HEAD);
          break;

        case UserRole.DEPARTMENT_HEAD:
          this.roles = UserRoleDropDown.filter(role => (role.key !== UserRole.ADMIN && role.key !== UserRole.SUB_ADMIN));
          break;
        default:
          break;
      }
    });
  }

  switchRole(role: MatSelectChange) {
    this.authService.switchRole(role.value).subscribe((rs: any) => {
      const { token } = rs;
      localStorage.setItem(ACCESS_TOKEN_NAME, token);
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    });
  }

  logout() {
    this.authService.logout();
  }
  initTheme() {
    const theme = localStorage.getItem('theme');
    this.themeData = theme;
    document.body.classList.value = ''
    if (!theme || theme === 'dark') {
      this.isChecked = false;
      document.body.classList.add('dark');
    } else {
      document.body.classList.add('light');
      this.isChecked = true;
    }
  }
  toggleTheme() {
    const theme = localStorage.getItem('theme');
    if (!theme || theme === 'dark') {
      localStorage.setItem('theme', 'light');
      document.body.classList.add('light');
    } else {
      localStorage.setItem('theme', 'dark');
      document.body.classList.remove('light');
    }
  }
  setLang(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  openSetting() {
    this.isSettingOpen = true;
  }
  onLangChanged() {
    if (this.isChecked) {
      document.body.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
    this.isChecked = !this.isChecked;
  }
}
