import { Component, OnInit } from '@angular/core';
import { LoadingComponent } from '@shared/components/loading/loading.component';
@Component({
  selector: 'app-subadmin-layout',
  templateUrl: './subadmin-layout.component.html',
  styleUrls: ['./subadmin-layout.component.scss'],
})
export class SubadminLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
