export const UserRole = {
  ADMIN: 'admin',
  SUB_ADMIN: 'sub-admin',
  DEPARTMENT_HEAD: 'department-head',
  USER: 'user',
};

export const SPINNER_DIAMETER = 50;
export const MAX_DEPARTMENT_ONCE_TIME = 5;
export const LEVEL_RANGE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const SNACKBAR_TIME = 7000;
export const PAGINATIONS = [5, 10, 25, 100];

export const UserRoleDropDown = [
  {key: 'admin', value: 'Admin'},
  {key: 'sub-admin', value: 'Sub-Admin'},
  {key: 'department-head', value: 'Department Head'},
  {key: 'user', value: 'User'},
]

export const ACCESS_TOKEN_NAME = 'access_token';