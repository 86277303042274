import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'moodie-dashboard';
  langs = ['en', 'hk'];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.addLangs(this.langs);
    const currentLanguage = localStorage.getItem('lang');
    if (currentLanguage) {
      // this.translate.setDefaultLang(currentLanguage);
      this.translate.use(currentLanguage);
    } else {
      // this.translate.setDefaultLang('en');
      // this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
  }
}
