<div id="chartAreaWrapper" style="width: 900px">
  <div id="chartAreaWrapper2" style="position: relative; height: 250px">
    <canvas
      baseChart
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [legend]="barChartLegend"
      [chartType]="barChartType"
    ></canvas>
  </div>
</div>

<!-- <div class="chartWrapper">
  <div class="chartAreaWrapper">
    <div class="chartAreaWrapper2">
        <canvas
          baseChart
          id="myChart"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
        ></canvas>
    </div>
  </div>
    
    <canvas id="myChartAxis" height="300" width="0"></canvas>
</div> -->