import { AuthModel } from './auth.model';
export class UserModel extends AuthModel {
  userId!: string;
  email!: string;
  lastName!: string;
  firstName!: string;
  birthday!: string;
  gender!: string;
  workingExperience!: number;
  countryRegion!: string;
  jobTitle!: string;
  occupation!: string;
  industry!: string;
  academicQualification!: string;
  description!: string;
  status!: string;
  role!: 'Admin' | 'Normal';
  baseRole!: string;
  password!: string;
  avatarUrl?: string;
  age: number;
  company: any;

  setUser(user: any): UserModel {
    this.userId = user.userId;
    this.email = user.email;
    this.lastName = user.lastName;
    this.firstName = user.firstName;
    this.birthday = user.birthday;
    this.gender = user.gender;
    this.workingExperience = user.workingExperience;
    this.countryRegion = user.countryRegion;
    this.jobTitle = user.jobTitle;
    this.occupation = user.occupation;
    this.industry = user.industry;
    this.academicQualification = user.academicQualification;
    this.description = user.description;
    this.status = user.status;
    this.role = user.role;
    this.password = user.password || '';
    this.firstName = user.firstName || '';
    this.email = user.email || '';
    this.avatarUrl = user.avatarUrl || '';
    this.age = user.age || 0;
    this.company = user.company;
    this.baseRole = user.baseRole;
    return this;
  }
}
