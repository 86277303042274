import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  /**
   * make a GET request
   *
   * @param url the URL
   * @param query the query params
   */
  public get(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<Object> {
    return this.http
      .get(`${environment.apiUrl}/${url}`, this.renderHeaderOptions(options))
      .pipe(catchError(this.handleError));
  }

  /**
   * make a PATCH request
   *
   * @param url the URL
   * @param body the POST data
   * @param query the query params
   */
  public patch(
    url: string,
    body: { [index: string]: any } = {},
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<Object> {
    return this.http
      .patch(
        `${environment.apiUrl}/${url}`,
        body,
        this.renderHeaderOptions(options)
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * make a POST request
   *
   * @param url the URL
   * @param body the POST data
   * @param query the query params
   */
  public post(
    url: string,
    body: { [index: string]: any } = {},
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<Object> {
    return this.http
      .post(
        `${environment.apiUrl}/${url}`,
        body,
        this.renderHeaderOptions(options)
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * make a PUT request
   *
   * @param url the URL
   * @param body the PUT data
   * @param query the query params
   */
   public put(
    url: string,
    body: { [index: string]: any } = {},
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<Object> {
    return this.http
      .put(
        `${environment.apiUrl}/${url}`,
        body,
        this.renderHeaderOptions(options)
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * make a DELETE request
   *
   * @param url the URL
   * @param query the query params
   */
  public delete(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<Object> {
    return this.http
      .delete(`${environment.apiUrl}/${url}`, options)
      .pipe(catchError(this.handleError));
  }

  renderHeaderOptions(options: any) {
    if (!options) options = {};
    if (!options.headers) options.headers = {};
    options.headers = {
      ...options.headers,
      'Accept-Language': this.translate.currentLang,
    };
    return options;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(error.error);
  }
}
