import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { SPINNER_DIAMETER } from '@utils/constants';
import {
  IResDataTodayBestPerformancer,
  ITodayBestPerformancer,
} from './today-best-performance.types';

@Component({
  selector: 'app-today-best-performance',
  templateUrl: './today-best-performance.component.html',
  styleUrls: ['./today-best-performance.component.scss'],
})
export class TodayBestPerformanceComponent implements OnInit {
  constructor(private readonly apiService: ApiService) {}
  data: ITodayBestPerformancer;
  loading: boolean = true;
  spinnerDiameter: number = SPINNER_DIAMETER;
  ngOnInit(): void {
    this.apiService
      .get({ url: 'user/todayBestPerformancer' })
      .then((res: IResDataTodayBestPerformancer) => {
        if(res.data && (res.data.computedNumberOfPractices || res.data.practiceCount)) {
          this.data = res.data;
        }
        
        this.loading = false;
      });
  }
}
