<div
  *ngIf="loading"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  "
>
  <mat-progress-spinner
    color="primary"
    mode="indeterminate"
    [diameter]="spinnerDiameter"
  >
  </mat-progress-spinner>
</div>
<div class="not-found-container" *ngIf="!loading && !data">
  <div>
      <img
        src="assets/media/data-not-found.svg"
        alt=""
        width="200"
      />
      <h3>{{ "error-message.no-data-found" | translate }}</h3>
      <h4>{{ "error-message.ask-user-pratice" | translate }}</h4>
  </div>
</div>
<div class="best-performance best-performance-wrap" *ngIf="!loading && data">
  <div class="best-performance__head">
    <div class="best-performance__user">
      <div class="best-performance__user__avatar">
        <img [src]="data.avatar" alt="avatar" />
      </div>
      <div class="best-performance__user__intro">
        <h5>{{ data.lastName + " " + data.firstName }}</h5>
        <p>{{ data?.department?.name }}</p>
      </div>
    </div>
    <!-- <div class="best-performance__type">
      <span>Project | Type</span>
    </div> -->
  </div>
  <div class="best-performance__score">
    <div class="best-performance__score--left">
      <app-widget-donut-score [score]="data.computedAverageScore || 0">
      </app-widget-donut-score>
    </div>
    <div class="best-performance__score--right">
      <p>
        <strong
          >{{ data.computedNumberOfPractices || 0 }}
          <sub>{{ "dashboard.times" | translate }}</sub></strong
        ><br />{{ "dashboard.total-practices" | translate }}
      </p>
      <p>
        <strong>{{ data.computedBestScore || 0 }}</strong
        ><br />{{ "dashboard.the-best-score" | translate }}
      </p>
    </div>
  </div>
  <!-- <a href="#" class="best-performance__href">details ></a> -->
</div>
