import { AUTH_REDIRECT_URL } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.auth.isAuthenticated() &&
      !this.auth.isAdmin() &&
      !this.auth.isSubAdmin()
    ) {
      return true;
    } else if (this.auth.isAuthenticated() && this.auth.isAdmin()) {
      this.router.navigate(['/admin']);
    } else if (this.auth.isAuthenticated() && this.auth.isSubAdmin()) {
      this.router.navigate(['/subadmin']);
    } else if (this.auth.isAuthenticated() && this.auth.isDepartmentHead()) {
      this.router.navigate(['/department-head']);
    } else {
      this.router.navigate(['/']);
    }

    return false;
    // if (this.auth.isAuthenticated() && !this.auth.isAdmin()) {
    //   console.log('auth: authenticated and is not an admin');
    //   return true;
    // } else if (this.auth.isAuthenticated() && this.auth.isAdmin()) {
    //   console.log('auth: authenticated and is an admin');
    //   this.router.navigate(['/admin']);
    //   return false;
    // } else {
    //   console.log('auth: unauthenticated');
    //   this.router.navigate(['/']);
    //   return false;
    // }
  }
}
