import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.scss'],
})
export class MListGroupComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
