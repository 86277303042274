import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrap-thread',
  templateUrl: './wrap-thread.component.html',
  styleUrls: ['./wrap-thread.component.scss'],
})
export class WrapThreadComponent implements OnInit {
  @Input() title = '';
  @Input() subTitle = '';
  @Input() routeCreate = '';
  @Input() routeLabel = '';
  constructor() {}
  ngOnInit(): void {}
}
