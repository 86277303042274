import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lwebcam',
  templateUrl: './lwebcam.component.html',
  styleUrls: ['./lwebcam.component.scss']
})
export class LwebcamComponent implements OnInit {
  @ViewChild('videoPreview') videoPreview!: ElementRef;
  @ViewChild('drawCanvas') drawCanvas!: ElementRef;
  _stream!: MediaStream;
  @Input("stream") set stream(value: MediaStream) {
    if (value) {
      this._stream = value;
      this.videoPreview.nativeElement.srcObject = value;
      this.videoPreview.nativeElement.captureStream = this.videoPreview.nativeElement.captureStream || this.videoPreview.nativeElement.mozCaptureStream;
      // this.videoPreview.nativeElement.muted = true;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  takeSnapshot(option?: {useRaw?: boolean}) {
    const _video = this.videoPreview.nativeElement;
    const dimensions = {width: 0, height: 0};
    if (_video.videoWidth) {
      dimensions.width = _video.videoWidth;
      dimensions.height = _video.videoHeight;
    }
    const _canvas = this.drawCanvas.nativeElement;
    _canvas.width = dimensions.width;
    _canvas.height = dimensions.height;
    var data = _canvas.toDataURL('image/png');
    // paint snapshot image to canvas
    const context2d = _canvas.getContext('2d');
    context2d.drawImage(_video, 0, 0);
    if(option?.useRaw) {
      return _canvas.toDataURL("image/jpeg"); 
    } else {
      return _canvas.toDataURL("image/jpeg").split(';base64,')[1];
    }
  }

  // public takeSnapshot(): void {
  //   // set canvas size to actual video size
  //   const _video = this.nativeVideoElement;
  //   const dimensions = {width: this.width, height: this.height};
  //   if (_video.videoWidth) {
  //     dimensions.width = _video.videoWidth;
  //     dimensions.height = _video.videoHeight;
  //   }

  //   const _canvas = this.canvas.nativeElement;
  //   _canvas.width = dimensions.width;
  //   _canvas.height = dimensions.height;

  //   // paint snapshot image to canvas
  //   const context2d = _canvas.getContext('2d');
  //   context2d.drawImage(_video, 0, 0);

  //   // read canvas content as image
  //   const mimeType: string = this.imageType ? this.imageType : WebcamComponent.DEFAULT_IMAGE_TYPE;
  //   const quality: number = this.imageQuality ? this.imageQuality : WebcamComponent.DEFAULT_IMAGE_QUALITY;
  //   const dataUrl: string = _canvas.toDataURL(mimeType, quality);

  //   // get the ImageData object from the canvas' context.
  //   let imageData: ImageData = null;

  //   if (this.captureImageData) {
  //     imageData = context2d.getImageData(0, 0, _canvas.width, _canvas.height);
  //   }

  //   this.imageCapture.next(new WebcamImage(dataUrl, mimeType, imageData));
  // }
}
