import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class FaceVerificationGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(this.auth.hasFaceVerified());
    if (this.auth.canFaceVerify()) {
      return true;
    } else if (this.auth.hasFaceVerified()) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
