import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  isSettingOpen: boolean = false;
  newTheme = false;
  sub: any;

  constructor(
    private translate: TranslateService,
    private activeatedRoute: ActivatedRoute
  ) {
    const lang = this.activeatedRoute.snapshot.queryParamMap.get('lang');
    if (lang) this.translate.use(lang);
    this.sub = this.activeatedRoute
      .data
      .subscribe((v: any) =>  {
        this.newTheme = v.newTheme;
      });
  }

  ngOnInit(): void {}

  openSetting() {
    this.isSettingOpen = true;
  }

  setLang(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
