<div class="nav flex-wrapper flex-vertical">
  <div class="flex-item">
    <a class="logo" [routerLink]="['/']">
      <img src="/assets/media/sidebar-logo.png" alt="Moodie Logo" />
    </a>
  </div>
  <div class="flex-item">
    <div class="navbar-title">{{ "layout.main" | translate }}</div>
    <nav class="navbar flex-wrapper flex-vertical">
      <a
        class="nav-item"
        [routerLink]="['/dashboard/summary']"
        routerLinkActive="router-link-active"
      >
        <img src="/assets/media/icon-sb-summary.png" class="icon" />
        <span>{{ "layout.summary" | translate }}</span>
      </a>
      <a
        class="nav-item"
        [routerLink]="['/dashboard/projects']"
        routerLinkActive="router-link-active"
      >
        <img src="/assets/media/icon-sb-projects.png" class="icon" />
        <span>{{ "layout.projects" | translate }}</span>
      </a>
      <a
        class="nav-item"
        [routerLink]="['/dashboard/profile']"
        routerLinkActive="router-link-active"
      >
        <img src="/assets/media/icon-sb-profile.png" class="icon" />
        <span>{{ "layout.profile" | translate }}</span>
      </a>
      <!-- <a
        class="nav-item"
        [routerLink]="['/dashboard/achievements']"
        routerLinkActive="router-link-active"
      >
        <img src="/assets/media/icon-sb-achievements.png" class="icon" />
        <span>{{ "layout.achievements" | translate }}</span>
      </a> -->
      <a
        class="nav-item"
        [routerLink]="['/dashboard/references']"
        routerLinkActive="router-link-active"
      >
        <img src="/assets/media/icon-sb-references.png" class="icon" />
        <span>{{ "layout.references" | translate }}</span>
      </a>
      <a
        class="nav-item"
        [routerLink]="['/dashboard/academies']"
        routerLinkActive="router-link-active"
      >
        <img src="/assets/media/icon-sb-academy.png" class="icon" />
        <span>{{ "layout.academy" | translate }}</span>
      </a>
    </nav>
  </div>
  <div class="flex-item" style="position: absolute; bottom: 12px; width: 100%">
    <div class="action flex-wrapper">
      <div class="flex-item">
        <a class="setting" (click)="openSetting()"
          ><img src="/assets/media/icon-sb-cog.png" alt="Settings "
        /></a>
      </div>
      <div class="flex-all-space"></div>
      <div class="flex-item">
        <a class="setting"
          ><img
            src="/assets/media/icon-sb-power.png"
            alt="Logout"
            (click)="logout()"
        /></a>
      </div>
    </div>
  </div>
  <nz-modal
    [(nzVisible)]="isSettingOpen"
    nzCentered
    (nzOnCancel)="isSettingOpen = false"
    [nzFooter]="null"
  >
    <ng-container *nzModalContent>
      <div fxFLex fxLayout="column">
        <!-- <div
        class="row"
        fxFlex
        fxLayout="row"
        fxLayoutAlign="flex-start center"
      >
        <div class="title" fxFlex="30%">
          <p>{{ "common.theme" | translate }}</p>
        </div>
        <div class="content" fxFlex="70%">
          <nz-switch
            [(ngModel)]="isChecked"
            [nzControl]="true"
            (click)="onLangChanged()"
          ></nz-switch>
        </div>
      </div> -->
        <div class="setting-container" fxFlex fxLayout="column">
          <div
            class="row modal-lang"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="flex-start center"
          >
            <div class="title text-center" fxFlex="30%">
              <p>{{ "common.language" | translate }}</p>
            </div>
            <div
              class="content"
              fxFlex="70%"
              fxLayout="row"
              style="justify-content: center"
            >
              <p
                (click)="setLang('hk')"
                style="margin-right: 12px; cursor: pointer"
              >
                繁
              </p>
              <p
                (click)="setLang('en')"
                style="margin-right: 12px; cursor: pointer"
              >
                ENG
              </p>
            </div>
          </div>
          <div
            *ngIf="roles.length > 0"
            class="row modal-lang"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="flex-start center"
          >
            <div class="title text-center" fxFlex="30%">
              <p>{{ "common.role" | translate }}</p>
            </div>
            <div
              class="content"
              fxFlex="70%"
              fxLayout="row"
              style="justify-content: center"
            >
              <mat-form-field appearance="fill">
                <mat-label>{{ "common.role" | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="selectedRole"
                  (selectionChange)="switchRole($event)"
                >
                  <mat-option *ngFor="let role of roles" [value]="role.key">
                    {{ "common." + role.key | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- <div fxFlex="30%">
            <button
              type="button"
              class="ant-btn-primary btn-create"
              (click)="switchRole()"
            >
              {{ "project.submit" | translate }}
            </button>
          </div> -->
          </div>
        </div>
      </div>
    </ng-container>

    <div *nzModalFooter></div>
  </nz-modal>
</div>
