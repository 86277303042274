<div class="container">
  <div class="title">{{ "term-of-use.general" | translate }}</div>
  <div class="content">{{ "term-of-use.general-content" | translate }}</div>
  <div class="content">{{ "term-of-use.general-content2" | translate }}</div>

  <div class="title">
    {{ "term-of-use.registration-and-submission-of-content" | translate }}
  </div>
  <div class="content">{{ "term-of-use.registration" | translate }}</div>
  <div class="content">
    {{ "term-of-use.registration-content" | translate }}
  </div>
  <div class="content">
    {{ "term-of-use.general-content-title" | translate }}
  </div>
  <div class="content">
    {{ "term-of-use.general-content-content" | translate }}
  </div>

  <div class="title">
    {{ "term-of-use.your-representations-and-warranties" | translate }}
  </div>
  <div class="content">
    {{ "term-of-use.your-representations-and-warranties-content" | translate }}
  </div>

  <div class="title">{{ "term-of-use.inappropriate-use" | translate }}</div>
  <div class="content">
    {{ "term-of-use.inappropriate-use-content" | translate }}
  </div>

  <div class="title">
    {{ "term-of-use.indemnification-of-moodie.ai" | translate }}
  </div>
  <div class="content">
    {{ "term-of-use.indemnification-of-moodie.ai-content" | translate }}
  </div>

  <div class="title">{{ "term-of-use.license-to-apps" | translate }}</div>
  <div class="content">
    {{ "term-of-use.license-to-apps-content" | translate }}
  </div>

  <div class="title">
    {{
      "term-of-use.no-representations-or-warranties-by-datality-lab" | translate
    }}
  </div>
  <div class="content">
    {{
      "term-of-use.no-representations-or-warranties-by-datality-lab-content"
        | translate
    }}
  </div>

  <div class="title">
    {{
      "term-of-use.limitation-on-types-of-damages-limitation-of-liability"
        | translate
    }}
  </div>
  <div class="content">
    {{
      "term-of-use.limitation-on-types-of-damages-limitation-of-liability-content"
        | translate
    }}
  </div>

  <div class="title">{{ "term-of-use.termination" | translate }}</div>
  <div class="content">{{ "term-of-use.termination-content" | translate }}</div>

  <div class="title">
    {{
      "term-of-use.proprietary-rights-in-service-content-and-activity-materials"
        | translate
    }}
  </div>
  <div class="content">
    {{
      "term-of-use.proprietary-rights-in-service-content-and-activity-materials-content"
        | translate
    }}
  </div>

  <div class="title">{{ "term-of-use.trademarks" | translate }}</div>
  <div class="content">{{ "term-of-use.trademarks-content" | translate }}</div>

  <div class="title">{{ "term-of-use.privacy" | translate }}</div>
  <div class="content">{{ "term-of-use.privacy-content" | translate }}</div>

  <div class="title">
    {{
      "term-of-use.governing-law-and-arbitration-no-class-action" | translate
    }}
  </div>
  <div class="content">
    {{
      "term-of-use.governing-law-and-arbitration-no-class-action-content"
        | translate
    }}
  </div>

  <div class="title">{{ "term-of-use.language" | translate }}</div>
  <div class="content">{{ "term-of-use.language-content" | translate }}</div>

  <div class="title">{{ "term-of-use.miscellaneous" | translate }}</div>
  <div class="content">
    {{ "term-of-use.miscellaneous-content" | translate }}
  </div>
</div>
<!-- <label
  class="checkbox"
  nz-checkbox
  [(ngModel)]="checked"
  (ngModelChange)="agree()"
  >{{ "term-of-use.have-read" | translate }}</label
> -->
