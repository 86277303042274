export class AuthModel {
  accessToken!: string;
  refreshToken!: string;
  faceVerified!: boolean;
  canCreateVideo!: boolean;
  faceLoginAttempt!: number;
  createVideoAttempt!: number;

  setAuth(auth: any) {
    this.accessToken = auth.accessToken;
    this.refreshToken = auth.refreshToken;
    this.faceVerified = auth.faceVerified;
    this.faceLoginAttempt = auth.faceLoginAttempt;
    this.canCreateVideo = auth.canCreateVideo
    this.createVideoAttempt = auth.createVideoAttempt;
  }

  setFaceVerified(verified: boolean) {
    this.faceVerified = verified;
  }
}
