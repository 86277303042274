import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private client: AxiosInstance;

  constructor(private translate: TranslateService) {
    if (!this.client) {
      this.client = axios.create({
        timeout: 120000,
        baseURL: environment.apiUrl,
      });
    }
    this.client.interceptors.request.use((config) => {
      config.headers['Accept-Language'] = translate.currentLang;
      config.headers['Authorization'] =
        'Bearer ' + localStorage.getItem('access_token');
      return config;
    });
  }

  public async get<T>(options: {
    url: string;
    params?: Record<any, any>;
  }): Promise<any> {
    try {
      const response: AxiosResponse = await this.client.request<T>({
        method: 'get',
        url: options.url,
        params: options.params,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else if (error.request) {
        throw error.request;
      }
      throw error.message;
    }
  }

  public async post<T>(options: {
    url: string;
    body?: Record<any, any>;
  }): Promise<any> {
    try {
      const response: AxiosResponse = await this.client.request<T>({
        method: 'post',
        url: options.url,
        data: options.body,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else if (error.request) {
        throw error.request;
      }
      throw error.message;
    }
  }

  public async postJSON<T>(options: {
    url: string;
    body: Record<any, any>;
  }): Promise<any> {
    try {
      const response: AxiosResponse = await this.client.request<T>({
        method: 'post',
        url: options.url,
        headers: { 'Content-Type': 'application/json' },
        data: options.body,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else if (error.request) {
        throw error.request;
      }
      throw error.message;
    }
  }

  public async patch<T>(options: {
    url: string;
    body?: Record<any, any>;
  }): Promise<any> {
    try {
      const response: AxiosResponse = await this.client.request<T>({
        method: 'patch',
        url: options.url,
        data: options.body,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else if (error.request) {
        throw error.request;
      }
      throw error.message;
    }
  }

  public async patchJSON<T>(options: {
    url: string;
    body?: Record<any, any>;
  }): Promise<any> {
    try {
      const response: AxiosResponse = await this.client.request<T>({
        method: 'patch',
        url: options.url,
        data: options.body,
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else if (error.request) {
        throw error.request;
      }
      throw error.message;
    }
  }

  public async delete<T>(options: {
    url: string;
    params?: Record<any, any>;
  }): Promise<any> {
    try {
      const response: AxiosResponse = await this.client.request<T>({
        method: 'delete',
        url: options.url,
        params: options.params,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else if (error.request) {
        throw error.request;
      }
      throw error.message;
    }
  }
}
