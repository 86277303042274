<div class="container">
  <div class="container-header">
    <div class="flex-wrapper" fxLayoutAlign="flex-start center">
      <div>{{ "common.today-highlight" | translate }}</div>
      <div class="info-text">
        {{ "common.top-users-of-the-day" | translate }}
      </div>
      <div class="dot bg-lg-8"></div>
      <div class="dot-bar bg-lg-8"></div>
    </div>
  </div>
  <div class="container-body">
    <form [formGroup]="searchForm">
      <div class="flex-wrapper flex-item-center" fxLayoutAlign="flex-start center">
        <mat-form-field class="mat-form-field-no-label mat-form-field-clean mat-form-field-no-error"
          appearance="outline" style="width: 200px; margin-right: 24px">
          <mat-select matNativeControl formControlName="department" (valueChange)="filter($event)" placeholder="All">
            <mat-option [value]="">ALL</mat-option>
            <mat-option [value]="option.id" *ngFor="let option of departments; index as i">{{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div *ngIf="dataSource.length" class="content">
      <div class="left">
        <div class="top-item" *ngFor="let item of top; let i = index">
          <img src="/assets/media/icons/icon-1st.png" alt="" *ngIf="i===0" />
          <img src="/assets/media/icons/icon-2nd.png" alt="" *ngIf="i===1" />
          <img src="/assets/media/icons/icon-3rd.png" alt="" *ngIf="i===2" />
          <div
            class="row"
            style="display: flex; flex-direction: row; align-items: center; gap: 10px;"
          >
            <img
              class="avatar"
              [src]="item.avatarUrl"
              width="80px"
              height="80px"
            />
            <div class="column">
              <p>{{ item.lastName + " " + item.firstName }}</p>
              <p>{{ item.department ? item.department.name : '' }}</p>
            </div>
          </div>
          <!-- <div class="rank">{{ i + 1 }}</div> -->
          <p>{{ item.computedBestScore | number: '1.0-0' }}</p>
          <!-- <div>
            <p
              *ngIf="
                item.currentHighlight - item.previousHighlight < 0;
                else second
              "
            >
              <img src="/assets/media/up.png" />
              {{ item.currentHighlight - item.previousHighlight }}
            </p>
            <ng-template #second>
              <p
                *ngIf="
                  item.currentHighlight - item.previousHighlight > 0;
                  else equal
                "
              >
                <img src="/assets/media/down.png" />
                {{ item.currentHighlight - item.previousHighlight }}
              </p>
            </ng-template>
            <ng-template #equal>
              <img src="/assets/media/equal.png" />
            </ng-template>
          </div> -->
        </div>
      </div>
      <div class="right">
        <!-- <div class="bottom-item" *ngFor="let item of bottom; let i = index">
          <div class="rank">
            {{ i + 4 }}
          </div>
          <div class="row">
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px;">
              <img [src]="item.avatarUrl" class="avatar2" />
              <p>{{ item.lastName + " " + item.firstName }}</p>
            </div>
            <p>{{ item.department ? item.department.name : '' }}</p>
            <p>{{ item.average }}</p>
            <div>
              <p
                *ngIf="
                  item.currentHighlight - item.previousHighlight < 0;
                  else second
                "
              >
                <img src="/assets/media/up.png" />
                {{ item.previousHighlight - item.currentHighlight }}
              </p>
              <ng-template #second>
                <p
                  *ngIf="
                    item.currentHighlight - item.previousHighlight > 0;
                    else equal
                  "
                >
                  <img src="/assets/media/down.png" />
                  {{ item.currentHighlight - item.previousHighlight }}
                </p>
              </ng-template>
              <ng-template #equal>
                <img src="/assets/media/equal.png" />
              </ng-template>
            </div>
          </div>
        </div> -->
        <div class="content container-body blur-table">
          <table mat-table [dataSource]="dataSourceBottom" matSort>
            <ng-container matColumnDef="userId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "clients.user-id" | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.userId }}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "clients.name" | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <img [src]="element.avatarUrl" class="avatar2" />
                {{ element.lastName + " " + element.firstName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="department">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "common.department" | translate }}</th>
              <td mat-cell *matCellDef="let element">
                {{ element.department ? element.department.name : ''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="average">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "common.best-score" | translate }}</th>
              <td mat-cell *matCellDef="let element">
                {{ element.average | number: '1.0-0' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize"
            [length]="pageLength"
            showFirstLastButtons
            (page)="handlePageEvent($event)"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
