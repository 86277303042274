import { FaceVerificationGuard } from './guards/face-verification.guard';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { JwtTokenInterceptor } from './interceptors/jwt-token.interceptor';
import { ApiService } from './api/api.service';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return AuthService.getToken();
        }
      }
    }),
  ],
  exports: [
    HttpClientModule,
    CommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true,
    },
    AuthGuard,
    NoAuthGuard,
    FaceVerificationGuard,
    ApiService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
 }
