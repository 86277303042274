import { PageEvent } from '@angular/material/paginator';
import { PAGINATIONS } from './../../../../utils/constants';
import { ApiService } from './../../../core/api/api.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss'],
})
export class HighlightComponent implements OnInit, OnChanges {
  @Input() dataSource: any[];
  top: any[] = [];
  bottom: any[] = [];
  searchForm: FormGroup;
  departments: any[] = [];

  pageSizeOptions: number[] = PAGINATIONS;
  pageSize = 10;
  pageLength: number;


  @Output()
  onFilter: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  dataSourceBottom: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [
    'userId',
    'name',
    'department',
    'average',
  ];

  constructor(private fb: FormBuilder, private apiService: ApiService) { }


  ngOnInit(): void {
    // console.log('dataSource', this.dataSource);
    this.searchForm = this.fb.group({
      department: new FormControl(null),
    });
    this.getDepartments();
  }

  handlePageEvent(event: PageEvent) {
    this.updateData(this.dataSource.slice(0, event.pageSize));
  }

  ngOnChanges(changes: any) {
    const { dataSource } = changes;
    if(!dataSource && !dataSource.currentValue) return
    this.updateData(dataSource.currentValue.slice(0, this.pageSize));
  }

  updateData(users: any[]) {
    if (users.length > 3) {
      this.top = users.slice(0, 3);
      // this.bottom = this.dataSource.slice(3);
      this.dataSourceBottom = new MatTableDataSource(users.slice(3));
      this.dataSourceBottom.sort = this.sort;
    } else {
      this.top = users;
      this.dataSourceBottom = null;
    }
  }

  getDepartments() {
    this.apiService.get({ url: 'companies/departments' }).then(({ data }) => {
      this.departments = data.departments;
    });
  }

  filter(id: string) {
    this.onFilter.emit(id);
  }
}
