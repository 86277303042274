<div style="position: relative">
  <div style="position: absolute; top: 0px; left: 0; right: 0">
    <div class="text-center gauge-marker-label">{{ "common.average" | translate }}</div>
  </div>
  <div class="test">
    <canvas
      baseChart
      [datasets]="chartDatasets"
      [options]="chartOption"
      [colors]="chartColors"
      [chartType]="chartType"
    ></canvas>
  </div>
  <div style="padding:0 15px" class="flex-wrapper flex-space-between">
    <div class="gauge-marker-label">{{ "common.behind" | translate }}</div>
    <div class="gauge-marker-label">{{ "common.excellent" | translate }}</div>
  </div>
</div>
