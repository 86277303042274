<div class="container">
  <div class="title">{{ "policy.privacy-policy" | translate }}</div>
  <div class="content">{{ "policy.privacy-policy-content" | translate }}</div>

  <div class="title">
    {{ "policy.personal-information-collection-and-use" | translate }}
  </div>
  <div class="content">
    {{ "policy.personal-information-collection-and-use-content" | translate }}
  </div>

  <div class="title">{{ "policy.log-data" | translate }}</div>
  <div class="content">{{ "policy.log-data-content" | translate }}</div>

  <div class="title">{{ "policy.cookies" | translate }}</div>
  <div class="content">{{ "policy.cookies-content" | translate }}</div>

  <div class="title">{{ "policy.cookie-policy" | translate }}</div>
  <div class="content">{{ "policy.cookie-policy-content" | translate }}</div>

  <div class="title">{{ "policy.service-providers" | translate }}</div>
  <div class="content1">
    {{ "policy.service-providers-content1" | translate }}
  </div>
  <div class="newline-style">
    {{ "policy.service-providers-newline" | translate }}
  </div>
  <div class="content">
    {{ "policy.service-providers-content2" | translate }}
  </div>
  <div class="content1">
    {{ "policy.service-providers-content3" | translate }}
  </div>
  <div class="content">
    {{ "policy.service-providers-content4" | translate }}
  </div>
  <div class="title">{{ "policy.security" | translate }}</div>
  <div class="content">{{ "policy.security-content" | translate }}</div>

  <div class="title">{{ "policy.links-to-other-sites" | translate }}</div>
  <div class="content">
    {{ "policy.links-to-other-sites-content" | translate }}
  </div>

  <div class="title">{{ "policy.children-privacy" | translate }}</div>
  <div class="content">{{ "policy.children-privacy-content" | translate }}</div>

  <div class="title">
    {{ "policy.changes-to-this-privacy-policy" | translate }}
  </div>
  <div class="content">
    {{ "policy.changes-to-this-privacy-policy-content" | translate }}
  </div>

  <div *ngIf="currentLang === 'hk'" class="title">
    {{ "policy.language" | translate }}
  </div>
  <div *ngIf="currentLang === 'hk'" class="content">
    {{ "policy.language-content" | translate }}
  </div>

  <div class="title">{{ "policy.contact-us" | translate }}</div>
  <div class="content">{{ "policy.contact-us-content" | translate }}</div>
</div>
<!-- <label
  class="checkbox"
  nz-checkbox
  [(ngModel)]="checked"
  (ngModelChange)="agree()"
  >{{ "policy.have-read" | translate }}</label
> -->
