import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-bar-line',
  templateUrl: './widget-bar-line.component.html',
  styleUrls: ['./widget-bar-line.component.scss'],
})
export class WidgetBarLineComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  interval: any;
  theme: string = localStorage.getItem('theme');
  public barChartOptions: ChartOptions;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[];
  public barChartColors: Color[] = [];
  private translateSubcription: Subscription = new Subscription();

  @Input('data') data: number[][] = [];
  @Input('labels') public barChartLabels?: string[];
  constructor(private translate: TranslateService) {
    this.translateSubcription = this.translate.onLangChange.subscribe(() => {
      this.renderChartOptions();
      this.renderChartData();
      if(!this.barChartLabels) this.renderChartLabels();
      this.handleData();
    });
  }
  ngAfterViewInit(): void {
    this.handleData();
  }

  ngOnInit(): void {
    this.renderChartOptions();
    this.renderChartData();
    if(!this.barChartLabels) this.renderChartLabels();
  }

  handleData() {
    const chartAreaWrapper = document.getElementById("chartAreaWrapper"); 
    const chartAreaWrapper2 = document.getElementById("chartAreaWrapper2"); 
    let chartAreaWrapperWidth = 100;
    const practiceNumber = [];
    const avgScore = [];
    const data = this.data.map((x) =>
      x.map((y) => ((y as any) === '' ? 0 : parseFloat(y + '')))
    );

    if(data && data[0]) {
      for (let index = 0; index < data[0].length; index++) {
        practiceNumber.push(data[0][index]);
        avgScore.push(data[1][index]);
      }
    }

    for (let index = 0; index < this.barChartLabels.length; index++) {
      this.barChartLabels[index] = `${this.barChartLabels[index]} (${practiceNumber[index]})`;
      chartAreaWrapperWidth = chartAreaWrapperWidth + 150;
    }

    // const practiceNumber = [data[0][0], data[0][3], data[0][2], data[0][1]];
    // const avgScore = [data[1][0], data[1][3], data[1][2], data[1][1]];


    // const bestScore = [
    //   data[2][0] - data[1][0],
    //   data[2][3] - data[1][3],
    //   data[2][2] - data[1][2],
    //   data[2][1] - data[1][1],
    // ];
    // console.log(data);
    // this.barChartLabels[0] = `${this.translate.instant(
    //   'dashboard.interview'
    // )} (${practiceNumber[0]})`;
    // this.barChartLabels[1] = `${this.translate.instant(
    //   'dashboard.board-room'
    // )} (${practiceNumber[1]})`;
    // this.barChartLabels[2] = `${this.translate.instant(
    //   'dashboard.public-speech'
    // )} (${practiceNumber[2]})`;
    // this.barChartLabels[3] = `${this.translate.instant('dashboard.media')} (${
    //   practiceNumber[3]
    // })`;
    this.barChartData[0].data = avgScore;
    // this.barChartData[1].data = bestScore;


    if(chartAreaWrapper.clientWidth < chartAreaWrapperWidth) {
      chartAreaWrapper2.style.width = `${chartAreaWrapperWidth}px`
    }
  }
  renderChartLabels() {
    this.barChartLabels = [
      this.translate.instant('dashboard.interview'),
      this.translate.instant('dashboard.board-room'),
      this.translate.instant('dashboard.public-speech'),
      this.translate.instant('dashboard.media'),
    ];
  }

  renderChartData() {
    this.barChartData = [
      // { data: [30, 40, 20, 40], label: 'Number of Practices', type: 'line', borderColor: "#00FFF6", backgroundColor: "rgba(255,255,255, 0)", hoverBackgroundColor: "#00FFF6" },
      {
        data: [45, 45, 45, 45],
        label: this.translate.instant('clients.average-score'),
        barThickness: 40,
        backgroundColor: '#896AEF',
        hoverBackgroundColor: '#896AEF',
        stack: 'a',
      },
      // {
      //   data: [24, 24, 24, 24],
      //   label: this.translate.instant('clients.best-score'),
      //   barThickness: 40,
      //   backgroundColor: '#30DAFF',
      //   hoverBackgroundColor: '#30DAFF',
      //   stack: 'a',
      // },
    ];
  }

  renderChartOptions() {
    const theme = localStorage.getItem('theme');
    if (!theme || theme === 'dark') {
      this.barChartOptions = {
        elements: {
          line: {
            tension: 0,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          labels: {
            fontColor: 'white',
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (item, d) => {
              if (item.datasetIndex == 1) {
                return (
                  this.translate.instant('clients.best-score') +
                  ': ' +
                  ((d.datasets[0].data[item.index] as number) +
                    (d.datasets[1].data[item.index] as number)) +
                  ''
                );
              } else {
                return (
                  this.translate.instant('clients.average-score') +
                  ': ' +
                  d.datasets[item.datasetIndex].data[item.index] +
                  ''
                );
              }
            },
            title: (item, d) => {
              return item[0].label;
            },
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.2)',
              },
              ticks: {
                beginAtZero: true,
                fontColor: 'white',
                suggestedMax: 100,
                stepSize: 20,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontFamily:
                  'Century Gothic, CenturyGothic, AppleGothic, sans-serif',
                fontColor: 'white',
                callback: (value: string) => {
                  return value.length > 20 ? value.substring(0, 20) + '...' : value;
                },
              },
            },
          ],
        },
      };
    } else {
      this.barChartOptions = {
        elements: {
          line: {
            tension: 0,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          labels: {
            fontColor: 'black',
          },
        },
        // animation: {
        //   onComplete: (animation) => {
        //           var sourceCanvas = animation.chart.canvas;
        //           var copyWidth = animation.scales['y-axis-0'].width - 10;
        //           var copyHeight = animation.scales['y-axis-0'].height + animation.scales['y-axis-0'].top + 10;
        //           var targetCanvasCtx = <HTMLCanvasElement> document.getElementById("myChartAxis");
        //           var targetCtx = targetCanvasCtx.getContext("2d");
        //           targetCtx.canvas.width = copyWidth;
        //           targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
        //       }
        //   },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (item, d) => {
              if (item.datasetIndex == 1) {
                return (
                  this.translate.instant('clients.best-score') +
                  ': ' +
                  ((d.datasets[0].data[item.index] as number) +
                    (d.datasets[1].data[item.index] as number)) +
                  ''
                );
              } else {
                return (
                  this.translate.instant('clients.average-score') +
                  ': ' +
                  d.datasets[item.datasetIndex].data[item.index] +
                  ''
                );
              }
            },
            title: (item, d) => {
              return '';
            },
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.2)',
              },
              ticks: {
                beginAtZero: true,
                fontColor: 'black',
                suggestedMax: 100,
                stepSize: 20,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontFamily:
                  'Century Gothic, CenturyGothic, AppleGothic, sans-serif',
                fontColor: 'black',
              },
            },
          ],
        },
      };
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.translateSubcription.unsubscribe();
  }
}
