import { CommonDataService } from './../../shared/services/common-data.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-guest-layout',
  templateUrl: './guest-layout.component.html',
  styleUrls: ['./guest-layout.component.scss']
})
export class GuestLayoutComponent implements OnInit {

  readonly BACKGROUND_DEFAULT = '/assets/media/new-layout/background-10.png';

  themeData = 'light';
  lang = 'en';
  background = this.BACKGROUND_DEFAULT;

  constructor(private translate: TranslateService, public commonDataService: CommonDataService) {}

  ngOnInit(): void {
    this.initTheme();
    this.initLang();
    this.onSetting();
  }

  initTheme() {
    document.body.classList.add('light');
  }

  initLang() {
    this.translate.use(this.lang);
    // window.location.reload();
  }

  onSetting() {
    this.commonDataService.currentGuestProjectSetting.subscribe((setting: any) => {
      if(setting) {
        if(setting.language) {
          this.lang = setting.language;
          localStorage.setItem('lang', setting.language);
          this.translate.use(setting.language);
        }
        if(setting.background) this.background = setting.background;
      }
    });
  }

}
