<ng-container
  *ngIf="!importPercent || (importPercent | async) === 100; else showPercent"
>
  <div class="import-user">
    <label class="custom-file-upload" *ngIf="!file">
      <input
        type="file"
        (change)="uploadFiles($event)"
        accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        #file
      />
      {{ "clients.import-user" | translate }}
    </label>
    <div *ngIf="file">
      {{ file.name }}
    </div>
  </div>

  <nz-progress
    *ngIf="!showSpinner && uploadProgress | async"
    [nzPercent]="uploadProgress | async"
    [nzShowInfo]="false"
  ></nz-progress>

  <!-- spinner -->
  <div *ngIf="showSpinner" class="upload-spinner">
    <img src="/assets/media/spinner.gif" alt="" />
  </div>

  <div class="text-center">
    <mat-error *ngIf="uploadInvalid">
      {{ uploadInvalid }}
    </mat-error>
  </div>

  <mat-error *ngIf="uploadError" class="upload-errors">
    <ng-container *ngIf="uploadError.emails">
      <p>Errors:</p>
      <div id="list-errors">
        <ul *ngFor="let item of uploadError.emails">
          <li>{{ item }}</li>
        </ul>
      </div>
    </ng-container>
  </mat-error>
</ng-container>

<ng-template #showPercent>
  <div class="text-center import-user">
    Import in progress...({{ this.importPercent | async }}%)
  </div>
  <nz-progress
    *ngIf="importPercent"
    [nzPercent]="importPercent | async"
    [nzShowInfo]="false"
  ></nz-progress>
</ng-template>
