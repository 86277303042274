import {Directive, HostListener, OnInit} from "@angular/core";
    
@Directive({
    selector: "[click-stop-propagation]"
})
export class ClickStopPropagation implements OnInit {

    constructor() {}

    ngOnInit() {}

    @HostListener("click", ["$event"]) onClick(event: any) {
        event.stopPropagation();
    }
}