import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.auth.isAuthenticated()) {
      if (this.auth.isAdmin()) {
        return true;
      } else if (this.auth.isSubAdmin()) {
        this.router.navigate(['/subadmin/summary']);
      } else if (!this.auth.isDepartmentHead()) {
        this.router.navigate(['/department-head']);
      }
    } else if (!this.auth.isAdmin()) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/']);
    }
    return false;
    // console.log('admin auth guard');
    // if (this.auth.isAuthenticated() && this.auth.isAdmin()) {
    //   console.log('admin: authenticated and is admin');
    //   return true;
    // } else if (this.auth.isAuthenticated() && !this.auth.isAdmin()) {
    //   console.log('admin: authenticated and is not an admin');
    //   this.router.navigate(['/dashboard']);
    //   return false;
    // } else {
    //   console.log('admin: unauthenticated');
    //   this.router.navigate(['/']);
    //   return false;
    // }
    // return true;
  }
}
