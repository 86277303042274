import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ProgressSpinnerDialogModule } from './components/progress-spinner/progress-spinner-dialog.module';
import { ClickStopPropagation } from './directives/click-stop-propagation.directive';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChartsModule } from 'ng2-charts';
import { GaugeChartModule } from 'angular-gauge-chart';
import { DemoNgZorroAntdModule } from './ng-zorro-antd.modules';

import { WidgetDonutScoreComponent } from './widgets/widget-donut-score/widget-donut-score.component';
import { WidgetGaugeScoreComponent } from './widgets/widget-gauge-score/widget-gauge-score.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { WidgetRadarPerformanceComponent } from './widgets/widget-radar-performance/widget-radar-performance.component';
import { WidgetBarLineComponent } from './widgets/widget-bar-line/widget-bar-line.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighlightComponent } from './components/highlight/highlight.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { LwebcamComponent } from './components/lwebcam/lwebcam.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from './components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImportMultipleUserComponent } from './components/import-multiple-user/import-multiple-user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MListGroupComponent } from './components/list-group/list-group.component';
import { TodayBestPerformanceComponent } from './components/today-best-performance/today-best-performance.component';
import { WrapThreadComponent } from './components/wrap-thread/wrap-thread.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { MatSelectModule } from '@angular/material/select';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ChartsModule,
    GaugeChartModule,
    PerfectScrollbarModule,
    DemoNgZorroAntdModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatSelectInfiniteScrollModule,
    TranslateModule.forChild(),
    MatFormFieldModule,
    ProgressSpinnerDialogModule,
    MatDialogModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule
  ],
  declarations: [
    WidgetDonutScoreComponent,
    WidgetGaugeScoreComponent,
    WidgetRadarPerformanceComponent,
    WidgetBarLineComponent,
    CreateUserComponent,
    HighlightComponent,
    TodayBestPerformanceComponent,
    LwebcamComponent,
    LoadingComponent,
    ImportMultipleUserComponent,
    ClickStopPropagation,
    MListGroupComponent,
    WrapThreadComponent,
    DialogConfirmComponent,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    ChartsModule,
    GaugeChartModule,
    DemoNgZorroAntdModule,
    ProgressSpinnerDialogModule,
    WidgetDonutScoreComponent,
    WidgetGaugeScoreComponent,
    WidgetRadarPerformanceComponent,
    WidgetBarLineComponent,
    PerfectScrollbarModule,
    CreateUserComponent,
    HighlightComponent,
    TodayBestPerformanceComponent,
    LwebcamComponent,
    LoadingComponent,
    ImportMultipleUserComponent,
    ClickStopPropagation,
    MatDialogModule,
    MListGroupComponent,
    WrapThreadComponent,
    DialogConfirmComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    FormBuilder,
  ],
})
export class SharedModule {}
